import { useState, useEffect } from 'react'
import { ReactFormGenerator } from 'react-form-builder2'
import { useParams } from 'react-router-dom'
import * as variables from './variables';
import { items } from './ToolbarOptions'

const requiredData = [
  'firstname',
  'lastname',
  'phone',
  'email',
  'brand',
  'price',
  'hours',
  'city',
  'country',
  'address',
  'zipcode',
  'nbimages',
  'date',
  'time',
  'type',
  'photographerprice',
  'config'
]

function FormGenerator() {
  const [data, setData] = useState([])
  const [structure, setStructure] = useState([])
  const [actionName, setActionName] = useState([])
  const [endpoint, setEndpoint] = useState([])
  const { slug } = useParams()

  useEffect(() => {
    fetch(process.env.REACT_APP_FORMS_API_URL + `api/v1/form/get/${slug}`)
    .then(res => res.json())
    .then(
      (result) => {
        const data = result.data[0]
        console.log(data)
        let schema = JSON.parse(data.schema)
        console.log(schema)
        setActionName(data.label)
        setEndpoint(data.endpoint)
        setData(schema.task_data)
        let obj = {}
        for (let i in schema.task_data) {
          if (schema.task_data[i].field_name) {
            obj[schema.task_data[i].field_name] = {
              inputValue: schema.task_data[i].inputValue,
              fieldValue: schema.task_data[i].fieldValue || null
            }
          }
        }
        setStructure(obj)
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.log(error)
      }
    )
  }, [slug])

  const handleSubmit = (v) => {
    let obj = {
      default: [],
      custom: []
    }
    for (let i in v) {
      if (requiredData.indexOf(structure[v[i].name].inputValue) !== -1) {
        if (structure[v[i].name].inputValue === 'date') {
          const t = parseInt((new Date(v[i].value).getTime() / 1000).toFixed(0))
          obj.default.push({ [structure[v[i].name].inputValue]: t.toString() })
        } else {
          obj.default.push({ [structure[v[i].name].inputValue]: structure[v[i].name].fieldValue ? structure[v[i].name].fieldValue : v[i].value })
        }
        // obj.push(encodeURIComponent(structure[v[i].name].inputValue) + '=' + encodeURIComponent(v[i].value))
      } else {
        obj.custom.push({ [structure[v[i].name].inputValue]: structure[v[i].name].fieldValue ? structure[v[i].name].fieldValue : v[i].value })
        // obj.push(encodeURIComponent(`custom[${structure[v[i].name].inputValue}]=${encodeURIComponent(v[i].value)}` ))
      }
    }
    let req = {
      answer: obj,
      endpoint: endpoint
    }
    saveAnswer(req)
  }

  const saveAnswer = (v) => {
    fetch(process.env.REACT_APP_FORMS_API_URL + `api/v1/form/answer`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(v),
    })
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-8 col-lg-6 mx-auto text-left my-5">
          <ReactFormGenerator
            variables={variables}
            items={items}
            data={data}
            actionName={actionName}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}

export default FormGenerator;
