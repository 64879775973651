import React from "react";
import { Registry } from 'react-form-builder2';

const InputHidden = React.forwardRef((props, ref) => {
  const { name, defaultValue, disabled } = props;
  return (
    <input className="form-control" type="hidden" ref={ref} name={name} defaultValue={defaultValue} disabled={disabled} />
  );
});

Registry.register('InputHidden', InputHidden);

export const items = [
  {
    key: 'Header',
    name: 'Header Text',
    icon: 'fas fa-heading',
    static: true,
    content: 'Placeholder Text...'
  },
  {
    key: 'Paragraph',
    name: 'Paragraph',
    static: true,
    icon: 'fa fa-paragraph',
    content: 'Placeholder Text...'
  },
  {
    key: 'TwoColumnRow',
    canHaveAnswer: false,
    name: 'Two Column Row',
    label: '',
    icon: 'fas fa-columns',
    field_name: 'two_col_row_',
  },
  {
    key: 'ThreeColumnRow',
    canHaveAnswer: false,
    name: 'Three Column Row',
    label: '',
    icon: 'fas fa-columns',
    field_name: 'three_col_row_',
  },
  {
    key: 'TextInput',
    canHaveAnswer: true,
    name: 'Text Input',
    label: 'Placeholder Label',
    icon: 'fa fa-font',
    field_name: 'text_input_',
  },
  {
    key: 'NumberInput',
    canHaveAnswer: true,
    name: 'Number Input',
    label: 'Placeholder Label',
    icon: 'fa fa-plus',
    field_name: 'number_input_',
  },
  {
    key: 'TextArea',
    canHaveAnswer: true,
    name: 'Multi-line Input',
    label: 'Placeholder Label',
    icon: 'fa fa-text-height',
    field_name: 'text_area_',
  },
  {
    key: 'DatePicker',
    canDefaultToday: true,
    dateFormat: 'MM/dd/yyyy',
    timeFormat: 'hh:mm aa',
    showTimeSelect: false,
    showTimeSelectOnly: false,
    name: 'Date',
    icon: 'fa fa-calendar',
    label: 'Placeholder Label',
    field_name: 'date_picker_',
  },
  {
    key: 'Dropdown',
    canHaveAnswer: true,
    name: 'Dropdown',
    icon: 'far fa-caret-square-down',
    label: 'Placeholder Label',
    field_name: 'dropdown_',
    options: [],
  },
  {
    key: 'Signature',
    canReadOnly: true,
    name: 'Signature',
    icon: 'fas fa-pen-square',
    label: 'Signature',
    field_name: 'signature_',
  },
  {
    key: 'InputHidden',
    canHaveAnswer: true,
    name: 'Input Hidden',
    icon: 'far fa-eye-slash',
    label: '',
    field_name: 'hidden_',
    forwardRef: true,
    type: 'custom',
    element: 'CustomElement',
    component: InputHidden,
  },
];